import '../components_css/contact.css'
import "../App.css"

function Contact() {
    return (
        <div className='page'>
            <h1>Contact</h1>
            <a href="mailto:mathieupelletier22@gmail.com?subject=We%20are%20interested%20in%20your%20services" ><img src="img/email.svg"></img></a>
            <h2>Email Me</h2>
            <a href="tel:+7809384754"><img src="img/phone.png"></img></a>
            <h2>Call Me</h2>
            <a href="https://www.linkedin.com/in/mathieu-pelletier-a4b95386/"><img src="img/linkedin.png"></img></a>
            <h2>Connect With Me</h2>
        </div>
    );
}

export default Contact;