import '../components_css/home.css'
import "../App.css"

import { useNavigate } from "react-router-dom";

function Home() {

    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/contact';
        navigate(path);
    }
    return (
        <div className="page">
            <div class="homemain">
                {/* intro card description */}
                <div class="card">
                    <img src="/img/us.png" alt=""></img>
                    <div class="card-content">
                        <h2>
                            Mathieu Pelletier - Developer, Project Manager
                        </h2>
                        <a href="#" class="button">
                            A highly accomplished professional with over a decade of experience in software development and project management.
                            <br />
                            <div onClick={routeChange} class="bounce">
                                <u>Inquire</u>
                                <span class="material-symbols-outlined">
                                    arrow_right_alt
                                </span>
                            </div>

                        </a>
                    </div>
                </div>
                <div class="homemain-item">
                    <section id="summary">
                        <h3>Summary</h3>
                        <p>A highly accomplished professional with over a decade of experience in full-stack development and project management, specializing in SaaS applications and custom software delivery. Proven track record of managing multiple projects, ensuring on-time delivery and client satisfaction. Skilled in leading teams, troubleshooting complex issues, and maintaining high customer satisfaction.</p>
                    </section>

                    <section id="skills">
                        <h3>Skills</h3>
                        <div class="skill-category">
                            <h4>Front End</h4>
                            <ul>
                                <li>React, Django, Flask, Angular</li>
                                <li>JavaScript, TypeScript, jQuery</li>
                                <li>HTML5, CSS3</li>
                                <li><b>Tools</b> : Figma, Netlify, VSCode, VSStudio</li>
                            </ul>
                        </div>
                        <div class="skill-category">
                            <h4>Back End</h4>
                            <ul>
                                <li>ASP.NET MVC, C#, VB.NET, VBA, Python</li>
                                <li>SQL, XML</li>
                                <li><b>Source Control</b> : TFS, GIT</li>
                                <li><b>Tools</b> : SQL Server, VSCode, VSStudio, DBeaver</li>
                            </ul>
                        </div>
                        <div class="skill-category">
                            <h4>Professional</h4>
                            <ul>
                                <li>Project Management (Agile, Waterfall)</li>
                                <li>Client Relationship and Success</li>
                                <li>Business Process Enhancements</li>
                                <li><b>Tools</b> : Jira, Salesforce, Confluence</li>
                            </ul>
                        </div>
                    </section>

                    <section id="experience">
                        <h3>Professional Experience</h3>
                        <div class="job">
                            <h3>Project Manager, Customer Success</h3>
                            <h5>ONWARE - Edmonton, AB (2023-2024)</h5>
                            <ul>
                                <li>Project management of SaaS and custom software delivery</li>
                                <li>Requirement gathering and technical write-up</li>
                                <li>Incident management, prioritization and escalation</li>
                                <li>Production of proposals and cost estimates</li>
                                <li>Process mapping and improvement recommendations</li>
                            </ul>
                            <h3>Intermediate Software Developer</h3>
                            <h5>SCM Insurance - Edmonton, AB (2014-2023)</h5>
                            <ul>
                                <li>VB.NET In-house application support and improvement implementation</li>
                                <li>Web API using REST, enhancing the functionality of the Claim Processing System</li>
                                <li>Implementation of SQL Server updates, views, and stored procedures for new or updated projects</li>
                                <li>Automation and data migration using FTP, API or DB files</li>
                                <li>Managed accounts for clients such as Coca-Cola and Post Canada, implemented new business processes, and provided recommendations for improvements.</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Home;