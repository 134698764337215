import logo from './logo.svg';
import './App.css';
import Home from './components/home';
import About from './components/about';
import Services from './components/services';
import Projects from './components/projects';
import Contact from './components/contact';
import Header from './components/header';
import Footer from './components/footer';
import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route
} from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <Router>
        <Header/>
        <div className='container'>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/projects" element={<Projects/>}/>
            <Route path="/contact" element={<Contact/>}/>
          </Routes>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
