import '../components_css/header.css'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Header()
{
    return (
        <header>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:wght@300&display=swap" rel="stylesheet"></link>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>

            <nav className="navbar">
                <div className="navbar-container">
                    <a id="logo" href="/"> <img  id="imglogo" src="/img/logo.png" alt="" /><label id="lbllogo">MP Company</label></a>
                    <ul className="nav-menu">
                        <li className="nav-item">
                            <Link className="nav-links" to='/'>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-links" to='/about'>About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-links" to='/projects'>Projects</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-links" to='/contact'>Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;