import '../components_css/footer.css';

function Footer() {
    return (
        <footer>
            <nav className="footer-navbar">
                <div className="footer-info">
                    <p>&copy; 2024 MP Company. All rights reserved.</p>
                </div>
            </nav>
        </footer>
    );
}

export default Footer;