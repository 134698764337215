import '../components_css/projects.css'
import "../App.css"

function Projects() {
    return (
        <div className="page">
            <h1>Projects</h1>

            <section class="tiles">
                <article class="style1">
                    <span class="image">
                        <img src="img/webdesign.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Web Design/Development</h2>
                        <div class="content">
                            <ul>
                                <li>Intranet Maintenance and Support</li>
                                <li>Tailored Management Tools</li>
                                <li>Claim System Web Application</li>
                                <li>Web APIs</li>
                            </ul>
                        </div>
                    </a>
                </article>
                <article class="style2">
                    <span class="image">
                        <img src="img/customsoftware.jpg" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Custom Software</h2>
                        <div class="content">
                            <ul>
                                <li>Business Process Modernization</li>
                                <li>Automation</li>
                                <li>Document Manager for Claims</li>
                                <li>Excel Services</li>
                            </ul>
                        </div>
                    </a>
                </article>
                <article class="style3">
                    <span class="image">
                        <img src="img/contractor.png" alt="" />
                    </span>
                    <a href="generic.html">
                        <h2>Other</h2>
                        <div class="content">
                            <ul>
                                <li>Business Process Analysis and Optimization</li>
                                <li>IT Project Management and Oversight using tools like <u>Jira</u> and <u>Salesforce</u></li>
                                <li>Application Support and Strategic Recommendations</li>
                            </ul>
                        </div>
                    </a>
                </article>
            </section>
        </div>
    );
}

export default Projects;