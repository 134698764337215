import "../components_css/about.css"
import "../App.css"
import { useNavigate } from "react-router-dom";

function About() {
    return (
        <div className="page">
            <h1>Mathieu Pelletier's Resume</h1>
            <iframe src="Pelletier_Mathieu_Resume_2024.pdf" class="iframe"></iframe>
        </div>  
    );
}

export default About;